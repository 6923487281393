import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../components/layout'
import PageTransition from 'gatsby-plugin-page-transitions';
import Testimonials from '../../components/testimonials'
import Press from '../../components/press'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const ContactPage = ({ location }) => {
  const meta = {
    description: 'Get in touch with the Ultrafine team at hello@ultrafine.co.'
  }

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {sourceInstanceName: {eq: "images"}}) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  const banner = data.allFile.edges.find(el => el.node.name === 'parfait-banner');

  return (
    <Layout pageTitle="Contact Us" pageClass="template-contact" location={location} meta={meta}>
      <PageTransition>
        <div className="container">
          <div className="contact-page grid">
            <div className="contact-page__title">
              <h1 className="h2">Book a call</h1>
              <p className="contact-page__text">Great, let's book a call to talk about your project! Fill out this form or email us more details about the project and your availability at <a href="mailto:hello@ultrafine.co">hello@ultrafine.co</a>.</p>
            </div>
            <div className="contact-page__form">
              <form name="contact" method="POST" data-netlify="true" action="/contact/success">
                <input type="hidden" name="form-name" value="contact" />
                <div className="field">
                  <input
                    type="text"
                    name="name"
                    id="Contact-Name"
                    autoComplete="name"
                    placeholder="Name"
                    className="field__input"
                    aria-required="true"
                    required
                  />
                  <label
                    htmlFor="Contact-Name"
                    className="field__label"
                  >Name</label>
                </div>
                <div className="field">
                  <input
                    type="text"
                    name="email"
                    id="Contact-Email"
                    spellCheck="false"
                    autoCapitalize="off"
                    autoComplete="email"
                    placeholder="Email address"
                    aria-required="true"
                    className="field__input"
                    required
                  />
                  <label
                    htmlFor="Contact-Email"
                    className="field__label"
                  >Email address</label>
                </div>
                <div className="field">
                  <textarea
                    name="textarea"
                    id="Contact-Message"
                    placeholder="Tell us a little more about your project and when you're available for a call."
                    className="field__input"
                    aria-required="true"
                    required
                  ></textarea>
                  <label
                    htmlFor="Contact-Message"
                    className="field__label"
                  >Details and availability</label>
                </div>
                <div className="field">
                  <button className="button button--primary" type="submit">Send message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Testimonials />
        <Press />
        <div className="">
          <GatsbyImage
            image={getImage(banner.node)}
            alt="Glossify Education product page on ipad against neutral background"
            loading="lazy"
            className=""
          />
        </div>
      </PageTransition>
    </Layout>
  )
}

export default ContactPage